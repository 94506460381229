import React, { useEffect, useState } from "react";
import SubmitPost from "../components/submit"

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch( "https://my-app.zach-boyce.workers.dev/posts");
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, []);

  return (
    <div>
    <SubmitPost/>
      <ul>

      {posts.map((post, i) => (
        <li key={i}>
          <h2>
            {post.title}
          </h2>
          <h5>
          By: {post.username}
          </h5>
          <p>{post.content}</p>
        </li>
      ))}

      </ul>
    </div>
  );
};

export default Posts;