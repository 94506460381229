import React, { useState } from "react";

const SubmitPost = () => {
    const [inputs, setValues] = useState({
      title: "", username: "", content: ""
    });
  
    const set = (title) => {
      return ({ target: { value } }) => {
        setValues((oldValues) => ({ ...oldValues, [title]: value }));
      };
    };
  
    const sendPost = async () => {
      const response = await fetch('https://my-app.zach-boyce.workers.dev/posts', {
        method: 'POST',
        body: JSON.stringify(inputs)
      });
      if (response.status !== 200) {
        throw new Error(`Request failed: ${response.status}`); 
      }
    }
  
    const onSubmit = async (event) => {
      event.preventDefault(); // Prevent default submission
      try {
        await sendPost();
        window.location.reload();
      } catch (e) {
        alert(`Post failed! ${e.message}`);
      }
    }
   
    return (
      <form className="submission" onSubmit={onSubmit}> 
        <h2>Submit Your Own Post!</h2>
  
        <input 
          type="text" required
          value={inputs.title} onChange={set("title")}
          placeholder="Title"
        /><br/>
        
        <input
          type="text" required
          value={inputs.username} onChange={set("username")}
          placeholder="Username" 
        /><br/>
  
        <textarea required value={inputs.content} onChange={set("content")} placeholder="Something amazing..." /><br/>
        
  
        <button type="submit">Submit</button>
      </form>
    );
  }
  export default SubmitPost;